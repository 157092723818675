body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.has-text-terracotta {
  color: #d28a6b !important;
}
.has-text-azure {
  color: #223e48 !important;
}


.has-text-devfoundational {
  color: #cc4818 !important;
}
.has-text-foundational {
  color: #f08c00 !important;
}
.has-text-devproficient {
  color: #e6c101 !important;
}
.has-text-proficient {
  color: #8bb746 !important;
}
.has-text-devadvanced {
  color: #22782f !important;
}
.has-text-advanced {
  color: #233f4b !important;
}